import { Alert, Box, Container, createStyles, Group, Text, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconClick } from '@tabler/icons'

import { PictureCard } from '@/components/surfaces'
import { StudentModal } from '@/containers/navigation/StudentMenu'
import { useStudent } from '@/lib/hooks'
import { useNav } from '@/providers/NavProvider'
import { Channel as ChannelType, Site as SiteType } from '@/types'

interface Props {
  siteData: SiteType
  channelData: ChannelType
}

const useStyles = createStyles(theme => ({
  nav: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    backgroundColor: theme.colors.gray[0],
    opacity: 0.9,
    backdropFilter: 'blur(10px) saturate(160%) contrast(45%) brightness(140%)',
    WebkitBackdropFilter: 'blur(10px) saturate(160%) contrast(45%) brightness(140%)'
  },
  user: {
    width: '100%',
    cursor: 'pointer',
    '&:hover': { backgroundColor: theme.colors.gray[2] }
  }
}))

export default function StudentNav({ siteData, channelData }: Props) {
  // Hooks
  const { classes } = useStyles()
  const { studentData } = useStudent()
  const {
    studentModalOpen,
    setStudentModalOpen,
    studentModalOpenFromBuyButton,
    setStudentModalOpenFromBuyButton
  } = useNav()

  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  return (
    <nav className={classes.nav}>
      <Box className={classes.user} onClick={() => setStudentModalOpen?.(true)}>
        <Container size="xl" py="xs">
          <Group position="apart">
            <PictureCard
              title={studentData ? `${studentData.firstName} ${studentData.lastName}` : ''}
              picture={studentData?.picture}
              subtitle={
                <>
                  <Text weight="bold">
                    {studentData ? 'Comprando para:' : 'Selecione um aluno'}
                  </Text>
                </>
              }
              subtitlePosition="before"
              subtitleHiddenXs={false}
            />
            <Alert color="orange">
              <Group spacing="xs" align="flex-end">
                <IconClick size={isXs ? 16 : 20} />
                <Text size="xs" hidden={isXs}>
                  Clique nesta barra para {studentData ? 'trocar de' : 'selecionar um'} aluno
                </Text>
              </Group>
            </Alert>
          </Group>
        </Container>
      </Box>
      <StudentModal
        open={studentModalOpen || studentModalOpenFromBuyButton || false}
        onClose={() => {
          setStudentModalOpen?.(false)
          setStudentModalOpenFromBuyButton?.(false)
        }}
        siteData={siteData}
        channelData={channelData}
      />
    </nav>
  )
}
