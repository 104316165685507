import { LoadingOverlay } from '@mantine/core'
import { useRouter } from 'next/router'
import React from 'react'

import { Redirect, ViewError } from '@/components/commons/'
import { useFetch } from '@/lib/hooks'
import { getUrlString } from '@/lib/utils'
import { useAuth } from '@/providers/AuthProvider'
import { SiteData as SiteDataType } from '@/types'

interface Props {
  initialData?: SiteDataType
  children?: React.ReactNode
}

export default function UnrestrictedSiteGuard({ initialData, children }: Props) {
  // Hooks
  const router = useRouter()
  const { isAuthenticated } = useAuth() || {}

  // Constants
  const { callbackUrl, site: siteSlug } = router.query || {}
  const callbackUrlSlug = getUrlString(callbackUrl)

  // Fetch
  const { data: siteData, error } = useFetch([siteSlug ? `/${siteSlug}/` : null], {
    fallbackData: initialData?.site // rehydration if necessary
  })

  const isLoading = !siteData && !error

  // Render
  if (isLoading) return <LoadingOverlay visible /> // Loading

  if (siteData?.maintenance) return <Redirect url="/maintenance" replace />

  if (error) {
    return (
      <ViewError
        title="Erro"
        message="Ops... Houve um erro ao carregar esta página. Tente novamente ou entre em contato conosco."
      />
    ) // Error view if initial data and retrieve failed
  }

  if (isAuthenticated) return <Redirect url={callbackUrlSlug || '/accounts/'} replace />

  return <>{children}</>
}
