import { Box, Card, Container, Group, Space, Text, ThemeIcon, Title } from '@mantine/core'
import { IconZoomCode } from '@tabler/icons'

import { FindChannelForm } from '@/containers/forms'
import { Site as SiteType } from '@/types'

interface Props {
  siteData: SiteType
}

export default function FindChannel({ siteData }: Props) {
  return (
    <Container size="sm" p="xl">
      <Space h="xl" />
      <Card p="lg" withBorder>
        <Group sx={{ flexWrap: 'nowrap' }}>
          <ThemeIcon
            size={72}
            radius="md"
            mr="sm"
            sx={theme => ({ [theme.fn.smallerThan('xs')]: { display: 'none' } })}>
            <IconZoomCode size={48} />
          </ThemeIcon>
          <Box>
            <Title order={3}>Olá,</Title>
            <Text>
              Seja muito bem-vindo(a) ao site <strong>{siteData?.seller?.displayName}</strong>. Digite o
              código da loja que você deseja acessar.
            </Text>
          </Box>
        </Group>
        <Text size="sm" color="dimmed" italic my="xl">
          Caso não tenha recebido um código, peça para sua escola ou entre em contato com o nosso suporte.
        </Text>
        <FindChannelForm />
      </Card>
    </Container>
  )
}
