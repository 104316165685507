import { GetStaticPaths, GetStaticProps } from 'next'
import { NextSeo } from 'next-seo'

import UnrestrictedSiteGuard from '@/guards/UnrestrictedSiteGuard'
import AssembleLayout from '@/layouts/AssembleLayout'
import MainLayout from '@/layouts/MainLayout'
import { siteMiddleware } from '@/lib/middlewares'
import { urlHostResolver } from '@/lib/utils'
import { noIndex } from '@/lib/utils/next-seo.config'
import { SiteData as SiteDataType } from '@/types'
import SiteView from '@/views/sites/SiteView'

interface Props {
  initialData?: SiteDataType
}

export default function SitePage({ initialData }: Props) {
  const { site: siteData } = initialData || {}

  // SEO
  const urlSEO = siteData?.domain || urlHostResolver(siteData?.subdomain, '/')

  const commonSEOProps = {
    title: siteData?.title || siteData?.seller?.displayName,
    description: siteData?.description
  }

  return (
    <AssembleLayout
      Layout={MainLayout}
      layoutProps={{ displayCover: true }}
      View={SiteView}
      Guard={UnrestrictedSiteGuard}
      initialData={{ initialData }}>
      <NextSeo
        canonical={urlSEO}
        {...commonSEOProps}
        openGraph={{
          ...commonSEOProps,
          url: urlSEO,
          images: [
            { url: (siteData?.cover || siteData?.logo) as string, alt: commonSEOProps.title }
          ]
        }}
        {...(siteData?.privacy === 'private' ? noIndex : {})}
      />
    </AssembleLayout>
  )
}

export const getStaticPaths: GetStaticPaths = async () => {
  return { paths: [], fallback: 'blocking' }
}

export const getStaticProps: GetStaticProps = async context => {
  const { data: siteData, error } = await siteMiddleware(context)
  return error?.response?.status === 404
    ? { notFound: true, revalidate: 60 }
    : { props: { initialData: { site: siteData } }, revalidate: 60 }
} // revalidate time in seconds
