import { Modal } from '@mantine/core'

import { Channel as ChannelType, Site as SiteType } from '@/types'
import ProfilesView from '@/views/sites/ProfilesView'

interface Props {
  open: boolean
  onClose: () => void
  siteData: SiteType
  channelData?: ChannelType
}

export default function StudentModal({ open, onClose, siteData, channelData }: Props) {
  return (
    <Modal
      overflow="inside"
      opened={open}
      onClose={onClose}
      closeButtonLabel="Fechar"
      zIndex={201}
      size="100%"
      styles={{ modal: { height: '90vh' } }}>
      <ProfilesView initialData={{ site: siteData, channel: channelData }} modalView={true} />
    </Modal>
  )
}
