import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  createStyles,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title
} from '@mantine/core'
import { IconAlertCircle, IconBuildingStore, IconExternalLink } from '@tabler/icons'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { useFetch } from '@/lib/hooks'
import { Channel as ChannelType, Site as SiteType } from '@/types'

interface Props {
  siteData: SiteType
}

const useStyles = createStyles(theme => ({
  welcome: { display: 'flex', alignItems: 'center', gap: theme.spacing.xs },
  welcomeIcon: { [theme.fn.smallerThan('xs')]: { display: 'none' } },
  channel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing.lg,
    [theme.fn.smallerThan('xs')]: {
      display: 'grid',
      justifyContent: 'center',
      justifyItems: 'center',
      gap: theme.spacing.xs
    }
  },
  channelCta: {
    minWidth: '94px'
  },
  avatar: { border: `1px solid ${theme.colors.gray[3]}`, borderRadius: '50%' },
  content: {
    flexGrow: 1,
    overflowWrap: 'anywhere',
    [theme.fn.smallerThan('xs')]: { textAlign: 'center', width: '100%' }
  }
}))

export default function SelectChannel({ siteData }: Props) {
  // Hooks
  const router = useRouter()
  const { classes } = useStyles()

  // Constants
  const { site: siteSlug } = router?.query || {}

  // Fetch
  const { data: channelsData, error } = useFetch([siteSlug ? `/${siteSlug}/channels/` : null], {})
  const channels: ChannelType[] = channelsData?.results

  const isLoading = !channelsData && !error

  // Actions
  // const handleRedirect = (channel: ChannelType) => {
  //   setRedirectLoading(channel?.uid)
  //   if (channel?.site?.domain) {
  //     setRedirectLoading('')
  //     window.location.href = `https://${channel.site.domain}/${channel?.slug}`
  //     return null
  //   } else {
  //     setRedirectLoading('')
  //     window.location.href = `//${channel.site?.subdomain}.${process.env.NEXT_PUBLIC_ROOT_URL}/${channel.slug}`
  //     return null
  //   }
  // }

  const renderChannel = (channel: ChannelType) => {
    if (channel.site?.uid !== siteData.uid) {
      return (
        <Paper key={channel.uid} p="md" withBorder className={classes.channel}>
          {channel.site?.logo && <Avatar src={channel.site?.logo} size="lg" className={classes.avatar} />}
          <Box className={classes.content}>
            <Title order={4}>{channel.site?.title}</Title>
            <Badge variant="dot">canal externo: {channel.title}</Badge>
            {channel.description ? (
              <Text
                size="sm"
                italic
                dangerouslySetInnerHTML={{
                  __html: channel.description.replace(/\n/g, '<br />')
                }}
              />
            ) : null}
          </Box>
          <Button
            variant="outline"
            leftIcon={<IconExternalLink size={20} />}
            component="a"
            href={
              channel?.site?.domain
                ? `https://${channel.site.domain}/${channel?.slug}`
                : `https://${channel.site?.subdomain}.${process.env.NEXT_PUBLIC_ROOT_URL}/${channel.slug}`
            }
            className={classes.channelCta}>
            Entrar
          </Button>
        </Paper>
      )
    }
    return (
      <Paper key={channel.uid} p="md" withBorder className={classes.channel}>
        {channel.logo && <Avatar src={channel.logo} size="lg" className={classes.avatar} />}
        <Box className={classes.content}>
          <Title order={4}>{channel.title}</Title>
          {channel.description ? (
            <Text
              size="sm"
              italic
              dangerouslySetInnerHTML={{ __html: channel.description.replace(/\n/g, '<br />') }}
            />
          ) : null}
        </Box>
        <Button component={Link} href={`/${channel.slug}`} className={classes.channelCta}>
          Entrar
        </Button>
      </Paper>
    )
  }

  return (
    <Container size="lg" p="lg">
      <Group mb="xl">
        <ThemeIcon size={72} radius="md" mr="sm" className={classes.welcomeIcon}>
          <IconBuildingStore size={48} />
        </ThemeIcon>
        <Box>
          <Title order={3}>Olá,</Title>
          {siteData?.description ? (
            <Text>{siteData.description}</Text>
          ) : (
            <Text>
              Seja muito bem-vindo ao site <strong>{siteData?.seller?.displayName}</strong>.
            </Text>
          )}
          <Text>Escolha qual ambiente de compra você deseja acessar:</Text>
        </Box>
      </Group>
      <Container fluid p={0} sx={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading} />
        <Stack hidden={isLoading}>
          {Array.isArray(channels) && channels.length ? (
            channels.map(channel => renderChannel(channel))
          ) : (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title="Nenhum canal encontrado."
              color="orange"
              mb="md">
              <Text>
                Não encontramos nenhum canal para ser listado aqui. Entre em contato com o nosso
                suporte.
              </Text>
            </Alert>
          )}
        </Stack>
      </Container>
    </Container>
  )
}
