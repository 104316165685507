import { Avatar, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconBuildingStore } from '@tabler/icons'
import { useRouter } from 'next/router'
import React from 'react'

import { Breadcrumbs } from '@/components/ui/navigation/Breadcrumbs'
import { useFetch } from '@/lib/hooks'
import {
  Channel as ChannelType,
  Collection as CollectionType,
  Product as ProductType,
  Showcase as ShowcaseType,
  Site as SiteType,
  Store as StoreType
} from '@/types'

interface Data {
  site?: SiteType
  channel?: ChannelType
  store?: StoreType
  showcase?: ShowcaseType
  product?: ProductType
}

interface Props {
  initialData?: Data
  children?: React.ReactNode
}

export default function ChannelNav({ initialData }: Props) {
  // Hooks
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const router = useRouter()

  // Constants
  const {
    site: siteSlug,
    channel: channelSlug,
    store: storeSlug,
    showcase: showcaseSlug,
    collection: collectionSlug,
    product: productSlug
  } = router.query || {}

  const endpoints = {
    channel: `/${siteSlug}/channels/${channelSlug}/`,
    store: `/${siteSlug}/channels/${channelSlug}/stores/${storeSlug}/`,
    showcase: `/${siteSlug}/channels/${channelSlug}/stores/${storeSlug}/showcases/${showcaseSlug}/`,
    product: `/${siteSlug}/channels/${channelSlug}/stores/${storeSlug}/showcases/${showcaseSlug}/products/${productSlug}`
  }

  // Fetch && re-hydration if necessary
  const { data: channelData } = useFetch([channelSlug ? endpoints.channel : null], {
    fallbackData: initialData?.channel
  })
  const { data: storeData } = useFetch([storeSlug ? endpoints.store : null], {
    fallbackData: initialData?.store
  })
  const { data: showcaseData } = useFetch([showcaseSlug ? endpoints.showcase : null], {
    fallbackData: initialData?.showcase
  })
  const collection =
    showcaseData?.collections?.find(
      (collection: CollectionType) => collection.slug === collectionSlug
    ) || {}
  const { data: productData } = useFetch([productSlug ? endpoints.product : null], {
    fallbackData: initialData?.product
  })

  let homeIcon = <IconBuildingStore size={isXs ? 14 : 18} />
  let homeLabel = 'Inicial'

  if (channelData?.title !== initialData?.site?.title) {
    if (channelData?.logo) {
      homeIcon = <Avatar src={channelData.logo} alt={channelData?.title} size={isXs ? 14 : 18} />
    }
    homeLabel = channelData?.title
  }

  const breadcrumbsItems = [
    ...(channelSlug && channelData
      ? [
          {
            title: homeLabel,
            href: `/${channelSlug}`,
            icon: homeIcon,
            isActive: router?.pathname === '/_sites/[site]/[channel]'
          }
        ]
      : []),
    ...(storeSlug && storeData
      ? [
          {
            title: `${storeData.grade?.parent?.name}: ${storeData.displayName}`,
            href: `/${channelSlug}/${storeSlug}`,
            isActive: router?.pathname === '/_sites/[site]/[channel]/[store]'
          }
        ]
      : []),
    ...(showcaseSlug && showcaseData
      ? [
          {
            title: `${showcaseData.displayName} ${showcaseData.cycle}${
              collection?.displayName && collectionSlug ? ` - ${collection?.displayName}` : ''
            }`,
            href: `/${channelSlug}/${storeSlug}/${showcaseSlug}${
              collection && collectionSlug ? `?collection=${collectionSlug}` : ''
            }`,
            isActive: router?.pathname === '/_sites/[site]/[channel]/[store]/[showcase]'
          }
        ]
      : []),
    ...(productSlug && productData
      ? [
          {
            title: productData.title,
            href: `/${channelSlug}/${storeSlug}/${showcaseSlug}?product=${productData.slug}`,
            isActive: router?.pathname === '/_sites/[site]/[channel]/[store]/[showcase]/[product]'
          }
        ]
      : [])
  ].filter(item => Boolean(item.title))

  return <Breadcrumbs breadcrumbs={breadcrumbsItems} />
}
