import { Box, Container, LoadingOverlay } from '@mantine/core'

import { Site as SiteType } from '@/types'

interface Props {
  siteData: SiteType
}

export default function Static({ siteData }: Props) {
  const content = siteData?.staticPage?.content || ''
  return (
    <Container fluid p={0} m={0}>
      <LoadingOverlay visible={!content} />
      <Box dangerouslySetInnerHTML={{ __html: content }} />
    </Container>
  )
}
